.App {
  height: 100vh;
  position: relative;
}

.top-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
}

.dashboard {
  padding: 20px;
  height: calc(100vh - 60px); /* Adjust this value based on your top bar height */
  overflow: hidden;
}

.user-info {
  display: flex;
  align-items: center;
}

.widget-list-container {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 1000;
}
