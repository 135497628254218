body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sticky-note-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sticky-note-editor {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.3;
  color: #4a4a4a;
}

.sticky-note-editor .public-DraftEditor-content {
  height: 100%;
}

/* Custom scrollbar styles */
.sticky-note-editor::-webkit-scrollbar {
  width: 12px;  /* Doubled from 6px to 12px */
}

.sticky-note-editor::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

.sticky-note-editor::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;  /* Increased from 3px to 6px */
}

/* Adjust line spacing */
.sticky-note-editor .public-DraftStyleDefault-block {
  margin: 0.5em 0;
}
